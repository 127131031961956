<template>
  <app-modal-copy-business
    v-if="
      business.business_status_id == BUSINESS_STATUS.CREATED ||
      business.business_status_id == BUSINESS_STATUS.AMENDED
    "
    :show="showModalCopyBusiness"
    @copy-business="copyBusiness"
    @show="showModalCopyBusiness = $event"
    show_info="bank"
  />
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.bank_details.title") }}
      </h1>
      <app-icon
        v-if="showCopyBusinessIcon"
        name="ClipboardCopyIcon"
        class="w-6 text-gray-400 cursor-pointer"
        @click="showModalCopyBusiness = true"
      />
    </div>
    <p class="mt-4">
      {{ $t("business.bank_details.description") }}
    </p>
  </div>
  <div class="w-full">
    <form @submit.prevent="submitBusinessBank">
      <app-form-bank
        id="business.bank.id"
        name="business.bank.id"
        class="lg:w-7/12 w-full mb-5"
        :labelText="$t('general.bank')"
        :placeholder="$t('general.placeholder_bank_search')"
        v-model="business.bank.bank"
        :country_id="business.country?.id ?? 0"
        :errorMessage="errors.bank_id"
        :disabled="!editMode"
      />

      <app-form-input
        id="business.bank.account_number"
        name="business.bank.account_number"
        class="lg:w-7/12 w-full mb-5"
        type="number"
        :labelText="$t('business.bank_details.account_number')"
        v-model="business.bank.account_no"
        :errorMessage="errors.account_no"
        :disabled="!editMode"
        :numberOnly="true"
      />

      <app-form-input
        id="business.bank.confirm_account_number"
        name="business.bank.confirm_account_number"
        class="lg:w-7/12 w-full mb-5"
        type="text"
        :labelText="$t('business.bank_details.account_holder')"
        v-model="business.bank.account_holder"
        :errorMessage="errors.account_holder"
        :disabled="!editMode"
      />

      <app-button
        id="submit_bank_detail_button"
        name="submit_bank_detail_button"
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="lg:w-7/12 w-full"
      >
        {{ $t("general.next") }}
      </app-button>
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      showModalCopyBusiness: false,
      emptyErrors: {
        bank_id: [],
        account_no: [],
        account_holder: [],
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    showCopyBusinessIcon() {
      let statusBusinessAllow = [
        BUSINESS_STATUS.CREATED,
        BUSINESS_STATUS.AMENDED,
      ];

      return (
        this.all_business?.length > 0 &&
        statusBusinessAllow.includes(this.business.business_status_id)
      );
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },
  methods: {
    copyBusiness(business) {
      let bank = business.bank;

      bank.id = this.business?.bank?.id ?? null;
      bank.business_id = this.business?.id ?? null;
      bank.account_no = business.bank.account_no;
      bank.account_holder = business.bank.account_holder;

      bank.bank = business.bank.bank;
      bank.bank.name = business.bank.bank.full_name;
      bank.bank.icon = business.bank.bank.logo_url;

      this.business.bank = bank;
    },

    async submitBusinessBank() {
      this.resetState();

      const businessBanks = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessBank",
        this.business.bank
      );

      if (businessBanks == null) return;

      this.$router.push({ path: "/business/support-documents" });
    },

    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>